import React, {useState} from "react";
import aboutImg from '../img/about/about.png';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function About(){
    const { t } = useTranslation();
    return(
<div>
<div class="chose_area ">
<div class="container">
<div class="features_main_wrap">
<div class="row  align-items-center">
<div class="col-xl-5 col-lg-5 col-md-6">
<div class="about_image">
<img src={aboutImg} alt="" />;
</div>
</div>
<div class="col-xl-6 col-lg-6 col-md-6">
<div class="features_info">
<h3 > {t('About')}</h3>
<p >{t('descAbout')}
</p>

<div class="about_btn">
<a class="boxed-btn3-line" href="about.php" >{t('btnabout')}</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
    
     )

}
export default About;