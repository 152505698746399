import React, {useState} from "react";

import Transportaion from "../Components/Transportaion";
import About from"../Components/About";
import Slider from "../Components/slider";
import Service from "../Components/service";
import Contact from "../Components/contact";
import Estimate from "../Components/Estimate";
import Location from "../Components/location";
import Footer from "../Components/footer";
function Home(){
    return(
<div>


<Slider/>
<Transportaion/>
<Service/>
<Contact/>
<About/>
<Estimate/>
<Location/>
<Footer/>

</div>
    
     )

}
export default Home;