import React, {useState} from "react";

import Footer from "../Components/footer";


import service_details from'../img/service/service_details.png';


import service3_details from'../img/service/service3_details.png';
import service2_details from"../img/service/service2_details.png";
import service4_details from"../img/service/service4_details.png";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });

function Services(){
    const { t } = useTranslation();
    return(
<div>


<div class="bradcam_area bradcam_area2  bradcam_bg_2">
<div class="container">
<div class="row">
<div class="col-xl-12">
<div class="bradcam_text text-center">
<h3>{t('NosServices')}</h3>
</div>
</div>
</div>
</div>
</div>

<div class="service_details_area">
<div class="container">
<div class="row">
<div class="col-lg-4 col-md-4">
<div class="service_details_left">

<div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
<a class=" active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" datal-i18n="titleservice1">{t('Fret maritime')}</a>
<a class id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"datal-i18n="titleservice2">{t('terrestre')}</a>
<a class id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"datal-i18n="titleservice3">{t('Fret Aerien')}</a>
<a class id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-douane" role="tab" aria-controls="v-pills-douane" aria-selected="false"datal-i18n="titleservice4">{t('Dédouanement')}</a>
</div>
</div>
</div>
<div class="col-lg-8 col-md-8">
<div class="tab-content" id="v-pills-tabContent">
<div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
<div class="service_details_info">
<h3 datal-i18n="titleservice1">{t('Fret maritime')}</h3>

<p datal-i18n="description1service1">{t('desc1service1')}
</p>
<p datal-i18n="description2service1">{t('desc2service1')}
</p>
<p datal-i18n="descriptions3ervice1">{t('desc3service1')}</p>
<p datal-i18n="description4service1">
{t('desc4service1')}</p>
<p datal-i18n="description5service1">{t('desc5service1')}</p>
<p datal-i18n="description6service1">{t('desc6service1')}</p>
<p datal-i18n="description7service1">{t('desc7service1')}</p>


    <p datal-i18n="description8service1">{t('desc8service1')}</p>
<p datal-i18n="description9service1">{t('desc9service1')}</p>
</div>
<div class="service_thumb">

<img src={service_details} alt="" />
</div>
</div>
<div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
<div class="service_details_info">
<h3 datal-i18n="titleservice2">{t('terrestre')}</h3>
<p datal-i18n="description1service2">{t('desc1service2')}</p>
<p datal-i18n="description2service2">{t('desc2service2')}</p>
<p datal-i18n="description3service2">{t('desc3service2')}</p>
</div>
<div class="service_thumb">

<img src={service2_details} alt="" />
</div>


</div>
<div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

<div class="service_details_info">
<h3 datal-i18n="titleservice3">{t('Fret Aerien')}</h3>
<p datal-i18n="description1service3">{t('desc1service3')}

</p>
<p datal-i18n="description2service3">{t('desc2service3')}
</p>
<p datal-i18n="description3service3">{t('desc3service3')}</p>
<ul>
<li datal-i18n="description4service3">{t('desc4service3')}</li> 
<li datal-i18n="description5service3">{t('desc5service3')}</li> 
<li datal-i18n="description6service3">{t('desc6service3')}</li>
<li datal-i18n="description7service3">{t('desc7service3')}</li> 
<li datal-i18n="description8service3">{t('desc8service3')}</li>

</ul>
</div>
<div class="service_thumb">
<img src={service3_details} alt="" />;

</div>
</div>
<div class="tab-pane fade" id="v-pills-douane" role="tabpanel" aria-labelledby="v-pills-douane-tab">

<div class="service_details_info">
<h3  datal-i18n="titleservice4">{t('Dédouanement')}</h3>
<p datal-i18n="description1service4">
</p>
<p datal-i18n="description2service4">{t('desc1service4')}
</p>
<p datal-i18n="description3service4">{t('desc2service4')}
</p>
<p datal-i18n="description4service4">{t('desc3service4')}</p>
<p datal-i18n="description5service4">{t('desc4service4')}</p>
<p datal-i18n="description6service4">{t('desc5service4')}</p>
<p datal-i18n="description7service4"> {t('desc6service4')}</p>
<p datal-i18n="description8service4">{t('desc7service4')}</p>
<p datal-i18n="description9service4">{t('desc8service4')}</p>
</div>
<div class="service_thumb">

<img src={service4_details} alt="" />
</div>
</div>
</div>


</div>
</div>
</div>
</div>






<Footer/>



</div>
    
     )

}
export default Services;