import React, {useState} from "react";
import logo from '../img/logo.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });

function Navbar(){
  const { t } = useTranslation();
 
    return(
<div>


<header>
<div className="header-area ">
<div className="header-top_area d-none d-lg-block">
<div className="container">
<div className="row align-items-center">
<div className="col-xl-4 col-lg-4">
<div className="logo">
<a href="index.html">
<img src={logo} alt="Logo" />

</a>
</div>
</div>
<div className="col-xl-8 col-md-8">
<div className="header_right d-flex align-items-center">
<div className="short_contact_list">
<ul>
<li><a href="#"> <i class="fa fa-envelope"></i> <span class="__cf_email__" data-cfemail="f49d9a929bb4909b97999190da979b99"> contact@itts.com.tn</span></a></li>
<li><a href="#"> <i class="fa fa-phone"></i> (+216) 71 449 033 </a></li>
</ul>
</div>

</div>
</div>
</div>
</div>
</div>
<div id="sticky-header" className="main-header-area">
<div className="container">
<div className="header_bottom_border">
<div className="row align-items-center">
<div className="col-12 d-block d-lg-none">
<div className="logo">
<a href="index.html">
<img src={logo} alt="Logo" />
</a>
</div>
</div>
<div className="col-xl-9 col-lg-9">
<div className="main-menu  d-none d-lg-block">
<nav>
  <ul id="navigation">

  <li> 
    
    <a href="/"  >{t('Home')}   </a>
    </li>
  
  <li><a href="/service">{t('Services')} </a></li>

  <li> <a href="/about" phdatal-i18n="about">{t('About')}</a></li>
  
  
  <li><a href="/contact"datal-i18n="contact">{t('contact')}</a></li>
  </ul>

  </nav>
</div>
</div>
<div className="col-xl-3 col-lg-3 d-none d-lg-block">
<div className="Appointment justify-content-end">
<div class="col-xl-3 col-lg-3 d-none d-lg-block">
<div class="Appointment justify-content-end">
 


    <button onClick={()=>{
  i18n.changeLanguage("fr");
}}>FR</button>
<button onClick={()=>{
  i18n.changeLanguage("en");
}}>EN</button>
</div>
</div>
</div>
</div>
<div className="col-12">
<div className="mobile_menu d-block d-lg-none"></div>
</div>
</div>
</div>
</div>
</div>
</div>
</header>


</div>
    
     )

}
export default Navbar;