
import React from "react";
import service1 from'../img/service/1.png';
import service2 from'../img/service/2.png';
import service3 from'../img/service/3.png';
import service4 from'../img/service/7.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function Service(){
 



    const { t } = useTranslation();
    return(
<div>
<div class="service_area">
<div class="container">
<div class="row">
<div class="col-xl-12">
<div class="section_title mb-50 text-center">
<h3 >
   {t('NosServices')}
</h3>
</div>

</div>
</div>
<div class="row">
<div class="col-xl-12">
<div class="service_active owl-carousel">
<div class="single_service">
<div class="thumb">
<img src={service1} alt="" />;
</div>
<div class="service_info">
<h3 datal-i18n="titleservice1"><a href="service_details.html">{t('Fret maritime')}</a></h3>
<p datal-i18n="descriptionservice1">{t('desc1service1')}
</p>
</div>
</div>
<div class="single_service">
<div class="thumb">
<img src={service2} alt="" />;
</div>
<div class="service_info">
<h3 datal-i18n="titleservice2"><a href="service_details.html">{t('terrestre')}</a></h3>
<p datal-i18n="descriptionservice2">{t('desc1service2')} 

</p>
</div>
</div>
<div class="single_service">
<div class="thumb">
<img src={service3} alt="" />;
</div>
<div class="service_info">
<h3 datal-i18n="titleservice3"><a href="service_details.html">{t('Fret Aerien')}</a></h3>
<p datal-i18n="descriptionservice3">{t('desc1service3')}</p>
</div>
</div>
<div class="single_service">
<div class="thumb">
<img src={service4} alt="" />;
</div>
<div class="service_info">
<h3 datal-i18n="titleservice4"><a href="service_details.html">{t('Dédouanement')}</a></h3>
<p datal-i18n="descriptionservice4">{t('descriptionservice4')}</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    
     )

}
export default Service;
