import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import './css/bootstrap.min.css';
import './css/owl.carousel.min.css';
import "./css/magnific-popup.css";
import"./css/font-awesome.min.css";
import"./css/themify-icons.css";
import"./css/nice-select.css";
import"./css/flaticon.css";
import"./css/gijgo.css";
import"./css/animate.css";
import"./css/slick.css";
import"./css/slicknav.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
