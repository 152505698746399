import React, {useState} from "react";

import Estimate from "../Components/Estimate";

import Location from "../Components/location";
import Footer from "../Components/footer";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function About_us(){
    const { t } = useTranslation();
    return(
<div>


<div class="bradcam_area bradcam_bg_1">
<div class="container">
<div class="row">
<div class="col-xl-12">
<div class="bradcam_text text-center">
<h3 datal-i18n="about">{t('About')}</h3>
</div>
</div>
</div>
</div>
</div>

<div class="chose_area ">
<div class="container">
<div class="features_main_wrap">
<div class="row  align-items-center">

<div class="col-xl-12 col-lg-12 col-md-12">
<div class="features_info">
<h3 datal-i18n="titleAboutUs" >{t('About')}</h3>
<p datal-i18n="descriptionabout">{t('desc1service6')}
</p>
<p datal-i18n="descriptionabout2">{t('desc2service6')}</p>
<div class="about_btn">
<a class="boxed-btn3-line" href="/contact">{t('contact')}</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<Estimate/>
<Location/>
<Footer/>

</div>
    
     )

}
export default About_us;